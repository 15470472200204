
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import RestTable from '@/components/table/RestTable.vue';

// 激活政策
export default defineComponent({
  name: 'ActivatePolicy',
  components: { RestTable },
  setup() {
    const { https, mapper } = useInject();

    const tableOpts = {
      search: {
        fetch: true,
        remote: {
          action: https?.market.listActivatePolicy
        }
      },
      cols: [
        {
          field: 'id',
          label: 'ID',
          width: 100
        },
        {
          field: 'activateDesc',
          label: '活动描述'
        },
        {
          field: 'activateCode',
          label: '活动编号',
        },
        {
          field: 'activateAmt',
          label: '达标金额',
        },
        {
          field: 'frzAmt',
          label: '冻结金额',
        },
        {
          field: 'activateTerm',
          label: '激活期限(天)',
        },
        {
          field: 'rewardFlag',
          label: '返现标识',
          options: mapper?.market.activateRewardFlag
        },
        {
          field: 'crtTime',
          label: '创建时间'
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'id',
              visible: false
            },
            {
              field: 'activateDesc',
              label: '活动描述',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'activateCode',
              label: '活动编号',
              disabled: ['edit'],
              required: true
            },
            {
              field: 'activateAmt',
              label: '达标金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'frzAmt',
              label: '冻结金额',
              type: 'number',
              scale: 2,
              required: true
            },
            {
              field: 'activateTerm',
              label: '激活期限(天)',
              type: 'number',
              required: true
            },
            {
              field: 'rewardFlag',
              label: '返现标识',
              type: 'radio',
              options: mapper?.market.activateRewardFlag,
              defVal: '1',
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.market.editActivatePolicy },
        update: { action: https?.market.editActivatePolicy }
      }
    };
    return { tableOpts };
  }
});
